import { SquareBox, SquareBoxContainer } from "components/square-box"
import {
  faBalanceScale,
  faBriefcase,
  faBuilding,
  faCertificate,
  faEnvelope,
  faLocationArrow,
  faMoneyBill,
  faPhone,
} from "@fortawesome/free-solid-svg-icons"

import Container from "react-bootstrap/Container"
import Layout from "components/layout"
import LocalizedLink from "components/localized-link"
import React from "react"
import SEO from "components/seo"
import styles from "./index.module.scss"
import svgLogo from "images/logo/logotek.svg"
import useTranslations from "components/use-translations"

export default function Hakkimizda() {
  const {
    genel: {
      hakkimizda,
      iletisimeGec,
      harita,
      bilgiler,
      sirket,
      telefon,
      ePosta,
      adres,
    },
    hakkimizda: {
      seoTitle,
      seoDescription,
      misyonumuz,
      baslikYazi1,
      baslikYazi2,
      hakkimizdaParagraf1,
      hakkimizdaParagraf2,
      vergiDairesiveNumarasi,
      mersisNumarasi,
      ticaretSicilNumarasi,
      sermaye,
    },
  } = useTranslations()
  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        pathname="https://www.kodvizit.com/hakkimizda"
        datePublished="2013-01-31T09:34:45+00:00"
        dateModified="2020-07-17T16:53:13+00:00"
      />
      <Container
        className="text-center"
        style={{ paddingTop: 90, paddingBottom: 80, minHeight: 500 }}
      >
        <img
          src={svgLogo}
          className={styles.hakkimizdaLogo}
          width="200"
          alt="Kodvizit Logo"
        />
        <h5
          className="text-primary mt-5"
          style={{ letterSpacing: 2, fontSize: 18 }}
        >
          {misyonumuz}
        </h5>
        <h1
          className={`font-weight-bold ${styles.headertext}`}
          style={{ letterSpacing: -1 }}
        >
          {baslikYazi1}
        </h1>
        <h5 className="mt-5" style={{ lineHeight: 1.5 }}>
          <div dangerouslySetInnerHTML={{ __html: baslikYazi2 }}></div>
        </h5>

        <div className="mt-5 d-flex justify-content-center align-items-center">
          <a
            className="btn btn-primary shadow mx-2 d-flex justify-content-center align-items-center"
            href="#bilgiler"
            role="button"
            style={{ width: 200, height: 50 }}
          >
            {bilgiler}
          </a>

          <LocalizedLink
            to="/iletisim"
            className="btn btn-light shadow mx-2 d-flex justify-content-center align-items-center"
            role="button"
            style={{ width: 200, height: 50 }}
          >
            {iletisimeGec}
          </LocalizedLink>
        </div>
      </Container>
      <div className="border-top"></div>
      <Container
        className="text-left"
        style={{ paddingTop: 90, paddingBottom: 80 }}
      >
        <h1
          className="font-weight-bold text-center mb-4"
          style={{ fontSize: 42, letterSpacing: -1 }}
        >
          {hakkimizda}
        </h1>
        <div
          className="text-left"
          style={{ fontSize: 16, lineHeight: 1.5, wordSpacing: 1.5 }}
        >
          <p style={{ textIndent: "50px" }}>{hakkimizdaParagraf1}</p>
          <p style={{ textIndent: "50px" }}>{hakkimizdaParagraf2}</p>
        </div>
      </Container>
      <div className="border-top"></div>

      <div
        style={{
          overflow: "hidden",
          paddingBottom: "30.25%",
          position: "relative",
          height: 0,
        }}
      >
        <iframe
          title={harita}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3019.772855668941!2d29.427176315407916!3d40.810984979321226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cb2099d84b160d%3A0xf951a9fcb8423044!2sGaziler%2C%20%C5%9Eht.%20Erdem%20Demir%20Cd.%20No%3A30%2C%2041400%20Gebze%2FKocaeli!5e0!3m2!1str!2str!4v1592502876355!5m2!1str!2str"
          width="1000"
          height="400"
          style={{
            border: 0,
            left: 0,
            top: 0,
            height: "100%",
            width: "100%",
            position: "absolute",
          }}
        ></iframe>
      </div>
      <div className="border-top"></div>
      <Container
        id="bilgiler"
        className="text-center"
        style={{ paddingTop: 90, paddingBottom: 80, minHeight: 500 }}
      >
        <h1
          className="font-weight-bold"
          style={{ fontSize: 42, letterSpacing: -1 }}
        >
          {bilgiler}
        </h1>

        <SquareBoxContainer>
          <SquareBox
            className="bg-dark"
            href=""
            icon={faBuilding}
            header={sirket}
            text="Aydemirler Isıtma Soğutma Sistemleri Yazılım İth. İhr. San. ve Tic. Ltd. Şti."
          ></SquareBox>
          <SquareBox
            className="bg-dark"
            href="https://goo.gl/maps/LjM7YWmn4bNGC2WeA"
            icon={faLocationArrow}
            header={adres}
            text="Gaziler Mah. Şht erdem Demir Cad. No:30 Gebze/Kocaeli"
          ></SquareBox>
          <SquareBox
            className="bg-dark"
            href="mailto:bilgi@kodvizit.com"
            icon={faEnvelope}
            header={ePosta}
            text="bilgi@kodvizit.com"
          ></SquareBox>
          <SquareBox
            className="bg-dark"
            href=""
            icon={faCertificate}
            header={vergiDairesiveNumarasi}
            text="İlyasbey VD 1101010562"
          ></SquareBox>
          <SquareBox
            className="bg-dark"
            href=""
            icon={faBriefcase}
            header={mersisNumarasi}
            text="0110101056200001"
          ></SquareBox>
          <SquareBox
            className="bg-dark"
            href=""
            icon={faBalanceScale}
            header={ticaretSicilNumarasi}
            text="28821"
          ></SquareBox>
          <SquareBox
            className="bg-dark"
            href=""
            icon={faMoneyBill}
            header={sermaye}
            text="50.000 ₺"
          ></SquareBox>
        </SquareBoxContainer>
      </Container>
    </Layout>
  )
}
